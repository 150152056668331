import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
    selector: 'di-page-total-items',
    templateUrl: './di-page-total-items.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiPageTotalItemsComponent {
    @Input() range: number[];
    @Input() total: number;

    constructor() {}
}
